import { Menu, MenuButton, MenuList, MenuItem, Link, VStack, StackDivider } from '@chakra-ui/react'
import React, { useState } from 'react'
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons'

export const MenuLink = ({ title, links, children }) => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <Menu >
            < MenuButton justifyContent='center' alignContent='center' alignItems='center' onClick={toggleMenu}>
                {title} {!isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </MenuButton>
            <MenuList  >
                <VStack padding="0 0.4rem" spacing="0" divider={<StackDivider />} alignItems='flex-start' >
                    {links.map(link => (
                        <>
                            <Link href={link.to} style={{ width: '100%' }} isExternal>
                                <MenuItem key={link.title} fontSize="xs" >
                                    {link.title}
                                </MenuItem>
                            </Link>
                            {/* <Divider width={'90%'} /> */}
                        </>
                    ))}
                    {children}
                </VStack>
            </MenuList>
        </Menu >
    )
}

import React from "react";
import { Box } from "@chakra-ui/react";
import BounceLoader from "react-spinners/BounceLoader";

const LoadingScreen = () => {
    return (
        <Box className="loading" display="flex" justifyContent="center" alignItems="center" w="100vw" h="100vh">
            <BounceLoader size={100} color={"#008ABD"} />
        </Box>
    );
}

export default LoadingScreen;
import React, { Suspense } from 'react';
import { lazy } from '@loadable/component';
import styles from './Home.module.css';
import Header from './screens/Header';
import Footer from './screens/Footer';
import { Helmet } from 'react-helmet-async';
import LoadingScreen from './screens/Loader/LoadingScreen';

const MainContent = lazy(() => import('./screens/MainContent'));

const Home = () => {

    return (
        <div className={styles.container}>
            <Suspense fallback={<LoadingScreen />} >
                <Helmet>
                    <title>Home | LASGNET</title>
                    <meta name="description" content="Apply for an e-planning permit and get approved in a few days without any hassle. The e-Planning Permit is currently regulated by Lagos State Physical Planning Permit Authority (LASPPPA)." />
                    <link rel="canonical" href="/" />
                </Helmet>
                <Header />
                <MainContent />
                <Footer />
            </Suspense>
        </div>
    )
}


export default Home
import { Box, HStack, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { FooterNav } from '../../components/FooterNav/FooterNav';
import styles from './Footer.module.css';
import logo from '../../../../assets/images/epp-logo.svg';
import logoWebp from '../../../../assets/images/epp-logo.webp';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <Box width="90%" margin="0 auto" color='white' className={styles.footerContainer}>
                <FooterNav />
            </Box>
            <Box display='flex' justifyContent='center'
                paddingY={4} width='100%' backgroundColor='#eee' margin='auto'>
                <HStack spacing={4} width='90%'>
                    <Box width={'50%'}>
                        <Box>
                            <Link href="/">
                                <picture>
                                    <source srcSet={logoWebp} type="image/webp" />
                                    <source srcSet={logo} type="image/png" />
                                    <Image src={logo} alt="lagossepp" width={{ base: '8rem', md: '10rem' }} height='auto' />
                                </picture>
                            </Link>
                        </Box>
                    </Box>
                    <Box width={'50%'} alignItems='flex-end' display='flex' justifyContent='flex-end'>
                        <Text color='black' textAlign='end'> &copy; Copyright 2022. All Rights Reserved.</Text>
                    </Box>
                </HStack>
            </Box>
        </footer>
    )
}

export default Footer
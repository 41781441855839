import React, { Suspense } from 'react';
import { lazy } from '@loadable/component';
import { Routes, Route } from 'react-router-dom';
import LoadingScreen from './pages/Home/screens/Loader/LoadingScreen';


import { Home, NotFoundPage } from './pages';

const LazyServicePage = lazy(() => import('./pages/Services'));
const LazyMainContent = lazy(() => import('./pages/Services/screens/MainContent'));
const LazyPlanning = lazy(() => import('./pages/Services/screens/Planning'));
const LazyStage = lazy(() => import('./pages/Services/screens/Stage'));
const LazyFees = lazy(() => import('./pages/GetStarted/screens/Fees'));
const LazyGenReq = lazy(() => import('./pages/GetStarted/screens/GenReq'));
const LazyMain = lazy(() => import('./pages/GetStarted/screens/Main'));
const LazySpecificReq = lazy(() => import('./pages/GetStarted/screens/SpecificReq'));
const LazyStatTable = lazy(() => import('./pages/GetStarted/screens/StatTable'));
const LazyGetStartedPage = lazy(() => import('./pages/GetStarted'));
const LazyRegPage = lazy(() => import('./pages/Reg'));
const LazyFaqsPage = lazy(() => import('./pages/Faqs'));
const LazyContactPage = lazy(() => import('./pages/ContactUs'));
const LazySearchApp = lazy(() => import('./pages/SearchApplication'));

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Suspense fallback={<LoadingScreen />} ><LazyServicePage /></Suspense>}>
          <Route index element={<Suspense fallback={<LoadingScreen />} ><LazyMainContent /></Suspense>} />
          <Route path="planning-permit" element={<Suspense fallback={<LoadingScreen />} ><LazyPlanning /></Suspense>} />
          <Route path="stage-cert" element={<Suspense fallback={<LoadingScreen />} ><LazyStage /></Suspense>} />
        </Route>
        <Route path="/get-started" element={<Suspense fallback={<LoadingScreen />} ><LazyGetStartedPage /></Suspense>}>
          <Route index element={<Suspense fallback={<LoadingScreen />} ><LazyMain /></Suspense>} />
          <Route path="fees" element={<Suspense fallback={<LoadingScreen />} ><LazyFees /></Suspense>} />
          <Route path="general-requirements" element={<Suspense fallback={<LoadingScreen />} ><LazyGenReq /></Suspense>} />
          <Route path="specific-requirements" element={<Suspense fallback={<LoadingScreen />} ><LazySpecificReq /></Suspense>} />
          <Route path="statutory-clearance-table" element={<Suspense fallback={<LoadingScreen />} ><LazyStatTable /></Suspense>} />
        </Route>
        <Route path="/regulations-and-laws" element={<Suspense fallback={<LoadingScreen />} ><LazyRegPage /></Suspense>} />
        <Route path="/contact-us" element={<Suspense fallback={<LoadingScreen />} ><LazyContactPage /></Suspense>} />
        <Route path="/faqs" element={<Suspense fallback={<LoadingScreen />} ><LazyFaqsPage /></Suspense>} />
        <Route path="/search-applications" element={<Suspense fallback={<LoadingScreen />} ><LazySearchApp /></Suspense>} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;

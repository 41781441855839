import React from 'react';
import { Box, Heading, Link, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';

const NotFoundPage = () => {
    return (
        <Box
            d="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            color="gray.700"
        >
            <Helmet>
                <title>404 | LASGNET</title>
                <meta name="description" content="Page Not Found" />
                <link rel="canonical" href="/404" />
            </Helmet>
            <Box textAlign="center">
                <Heading as="h1" size="4xl">
                    404
                </Heading>
                <Text fontSize="xl" mb={4}>
                    Page Not Found
                </Text>
                <Link href="/">Go back to the home page</Link>
            </Box>
        </Box>
    );
};

export default NotFoundPage;
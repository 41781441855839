import { Box, Heading, HStack, VStack, Text, Image, Link, Center, Flex, List, ListItem } from '@chakra-ui/react'
import React from 'react';
import { NavLink } from 'react-router-dom';
import facebook from '../../../../assets/icons/fb-icon.svg';
import twitter from '../../../../assets/icons/twitter-icon.svg';
import linkedin from '../../../../assets/icons/linkedin-icon.svg';

const NLink = ({ to, title }) => (
    <NavLink to={to} activeClassName="active" className="nav-link">
        {title}
    </NavLink>
);

export const FooterNav = () => {

    return (
        <Box>
            <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems={{ base: 'center', md: 'flex-start' }} marginTop={4} marginBottom={6} gap={2}>
                <Box width={{ base: '100%', md: '30%' }}
                    padding={{ base: '2', md: '4' }}>
                    <VStack alignItems='flex-start' width={{ base: '100%' }} fontSize={{ base: 'sm' }}>
                        <Heading as="h3" size="md">
                            Agencies
                        </Heading>
                        <List spacing={1}>
                            <ListItem><Link href='https://laspppa.lagosstate.gov.ng/' isExternal>LASPPPA - Lagos State Physical Planning Permit Authority</Link></ListItem>
                            <ListItem><Link href='https://lasbca.lagosstate.gov.ng/' isExternal>LASBCA - Lagos State Building Control Agency</Link></ListItem>
                            <ListItem><Link href='https://lasura.lagosstate.gov.ng/' isExternal>LASURA - Lagos State Urban Renewal Agency</Link></ListItem>
                            <ListItem><Link href='https://laspema.lagosstate.gov.ng/' isExternal>LASPEMA - Lagos State Planning and Environmental Monitoring Authority</Link></ListItem>
                        </List>
                    </VStack>
                </Box>
                <Box width={{ base: '100%', md: '30%' }}
                    padding={{ base: '2', md: '4' }}>
                    <VStack alignItems='flex-start' width={{ base: '100%' }} fontSize={{ base: 'sm' }}>
                        <Heading as="h3" size="md">
                            Departments
                        </Heading>
                        <List spacing={1}>
                            <ListItem><NLink to="/services/" title='PPD - Physical Planning Department' /></ListItem>
                            <ListItem><NLink to="/services/" title='DMD - Development Matters Department' /></ListItem>
                            <ListItem><NLink to="/services/" title='RMPD - Regional & Master Plan Department' /></ListItem>
                            <ListItem><NLink to="/services/" title='LASPIC - Lagos State Planning Information Centre' /></ListItem>
                            <ListItem><NLink to="/services/" title='TSD - Technical Services Department' /></ListItem>
                            <ListItem><NLink to="/services/" title='UDD - Urban Development Department' /></ListItem>
                        </List>
                    </VStack>
                </Box>
                <Box width={{ base: '100%', md: '25%' }}
                    padding={{ base: '2', md: '4' }}>
                    <VStack alignItems='flex-start' width={{ base: '100%' }} fontSize={{ base: 'sm' }}>
                        <Heading as="h3" size="md">
                            Ministry
                        </Heading>
                        <List spacing={1}>
                            <ListItem><Link href='http://mppud.lagosstate.gov.ng/' isExternal>Ministry of Physical Planning and Urban Development</Link></ListItem>
                            <ListItem><Link href='https://lagosstate.gov.ng/' isExternal>Lagos State Government</Link></ListItem>
                            <ListItem><Link href='https://citizensgate.lagosstate.gov.ng/' isExternal>Citizens' Gate</Link></ListItem>
                        </List>
                    </VStack>
                    <VStack alignItems='flex-start' width={{ base: '100%' }} fontSize={{ base: 'sm' }} marginTop={4}>
                        <Heading as="h3" size="md">
                            Customer Support
                        </Heading>
                        <List spacing={1}>
                            <ListItem><NavLink to="/contact-us">Resources</NavLink></ListItem>
                            <ListItem><NavLink to="/faqs">FAQs</NavLink></ListItem>
                        </List>
                    </VStack>
                </Box>
                <Box width={{ base: '100%', md: '25%' }}
                    padding={{ base: '2', md: '4' }}>
                    <VStack alignItems='flex-start' width={{ base: '100%' }} fontSize={{ base: 'sm' }}>
                        <Heading as="h3" size="md">
                            Contact us.
                        </Heading>
                        <Text>
                            <Text as='span' fontWeight='bold'>Address:</Text> Head Office - Along Laspppa Road Off Oba Akinjobi Road, Ikeja Old Secretariat, Ikeja - State, Nigeria.
                        </Text>
                        <Text>
                            <Text as='span' fontWeight='bold'>Phone:</Text> 09082233117, 09082233118
                        </Text>
                        <Text>
                            <Text as='span' fontWeight='bold'>Email:</Text>  <a href="mailto:info@lagossepp.com">info@lagossepp.com</a>
                        </Text>

                        <HStack spacing={4} mt={4}>
                            <Center bgColor="#466583" borderRadius='md' width='48px' height='48px' as="a" href="https://www.facebook.com/lagossepp/" target="_blank" rel="noopener noreferrer">
                                <Image src={facebook} alt="facebook" width={6} height={6} />
                            </Center>
                            <Center bgColor="#466583" borderRadius='md' width='48px' height='48px' as="a" href="https://www.linkedin.com/lagossepp/" target="_blank" rel="noopener noreferrer">
                                <Image src={linkedin} alt="linkedIn" width={6} height={6} />
                            </Center>
                            <Center bgColor="#466583" borderRadius='md' width='48px' height='48px' as="a" href="https://twitter.com/lagossepp" target="_blank" rel="noopener noreferrer">
                                <Image src={twitter} alt="twitter" width={6} height={6} />
                            </Center>
                        </HStack>
                    </VStack>
                </Box>

            </Flex>
        </Box>
    )
}
